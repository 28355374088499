.chat-view{
    // background-color: rgba(150, 4, 4, 0.1);
    // backdrop-filter: blur(10px);

    display: grid;
    // flex-direction: column;
    grid-template-rows: 12fr 1fr;
    max-height: 100cqh;
    

    &__message{


        &-view{
            // min-height: 10rem;
            // height: fit-content;
            height: 100%;
            // max-height: 100%;
            width: 80%;
            margin: auto;
            // background-color: aqua;
            padding: 2rem;
            padding-top: 0;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            position: relative;
            // top: 100%;
            // transform: translateY(-100%);
            // align-items: center;
            gap: 0.5rem;

            // border: 2px solid white;


            &::-webkit-scrollbar{
                display: none;
            }
        }

        &-container{
            width: 100%;
            // border: 2px solid blue;
            position: relative;
            display: flex;
        }

        &__card{
            
            padding: 1rem 2rem;
            position: relative;
            min-width: 15rem;
            max-width: max(60%, 60rem);
            width: fit-content;
            // width: max(60%, 60rem);
            background-color: rgba(82, 114, 141, 0.226);
            border-radius: 1rem;
            word-wrap: break-word;
            backdrop-filter: blur(10px);
            // overflow-wrap: break-word;
            // top: 50%;
            // transform: translateY(-50%);
            
            
            &--sent{
                left: 100%;
                transform: translateX(calc(-100% - 2rem));
                border-top-right-radius: 0;
                background-color: rgba(0, 255, 0, 0.3);
                
            }
            &--received{
                background-color: rgba(255, 255, 255, 0.3);
                border-top-left-radius: 0;
                left: 2rem;
            }
        }
    }



    &__form{
        display: grid;
        grid-template-columns: 15fr 1fr;
        gap: 1rem;
        width: 80%;
        margin: auto;

        &>*{
            // border: 2px solid white;
            margin: auto;
        }
        
        
        &__input{
            width: 100%;
            margin: auto;
        }

        &__btn{
            width: 100%;

        }
    }
}