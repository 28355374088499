.title{
    height: fit-content;
    min-width: fit-content;
    width: 40%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    // aspect-ratio: 1;
    border-radius: 1rem;
    // @include glassmorph();


    &__heading{
        font-size: 5.5rem;
        text-transform: uppercase;
        color: $color-light;
        font-family: $font-audiowide;

        &>span{
            font-size: 1.5em;
        }
    }
    &__tagline{
        font-size: 1.6rem;
        text-transform: capitalize;
        color: $color-light;
        opacity: 0.8;
        letter-spacing: 2px;
        word-spacing: 0.5rem;
        text-align: center;
    }

    &__btn-group{
        margin-top: 4rem;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
}