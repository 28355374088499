.side-nav-bar__btn{
    --color: hsl(var(--h), var(--s), var(--l));
    background-image: linear-gradient(to bottom right, hsl(var(--h), var(--s), var(--l)), hsl(var(--h), var(--s), calc(var(--l) + 20%)));
    cursor: pointer;
    border: 2px solid hsl(var(--h), var(--s), calc(var(--l) + 20%));
    outline: 2px solid hsl(var(--h), var(--s), calc(var(--l) + 20%));
    outline-offset: 3px;
    color: hsl(var(--h), var(--s), calc(var(--l) + 100%));
    display: flex;
    justify-content: center;
    align-items: center;

    &--toggle{
        position: fixed;
        z-index: 9;
        right: 2rem;
        top: 2rem;
        width: 5rem;
        aspect-ratio: 1;
        border-radius: 50rem;
        // bottom: 0;
    }
    &--close{
        // cursor: pointer;
        position: absolute;
        // background-color: rgb(0, 88, 88);
        // z-index: 9;
        right: 0rem;
        top: 0rem;
        width: 4rem;
        aspect-ratio: 1;
        border-radius: 3rem 0rem 3rem 3rem;
        outline-offset: 3px;
    
        // border-radius: 50rem;
        // bottom: 0;
    
    }
}

.side-nav-bar{
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 0;
    max-width: 30rem;
    transition: 0.3s ease-in-out;
    @include glassmorph($color: black, $blur: 20px, $opacity: 0.25);
    border: none;
    outline: 1px solid rgba(0, 0, 0, 0.4);


    &__title{
        padding: 1rem 5rem 1.5rem 3rem;
        text-align: center;
        font-size: 2.2rem;
        font-weight: 500;
        font-family: $font-audiowide;
        color: white;
        border-bottom: 2px solid;
    }


    &__menu{
        width: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        &__item{
            width: 100%;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            text-transform: capitalize;
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.5);
            transition: 0.3s ease-in-out;
            // background-color: aqua;
            
            &:hover{
                background-color: rgba(255, 255, 255, 0.2);
                color: rgba(255, 255, 255, 0.9);
            }
        }
    }
}