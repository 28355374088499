.static-neon-button {
  --color: hsl(var(--h), var(--s), var(--l));
  // $color: $color-primary-light;
  backdrop-filter: blur(10px);
  background-color: transparent;
  border: 2px solid var(--color);
  outline: transparent;
  position: relative;
  overflow: hidden;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--color);
  $shadow-radius: 5px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--color);
    box-shadow: 0 0 1 * $shadow-radius var(--color), 0 0 3 * $shadow-radius var(--color),
    0 0 5 *  $shadow-radius var(--color), 0 0 10 *  $shadow-radius var(--color);
    --color-hover: hsl(var(--h), var(--s), calc(var(--l) - 55));
    
    &>*{
      --color-hover: hsl(var(--h), var(--s), calc(var(--l) - 15%)); 
      color: var(--color-hover);
      text-shadow: 0 0 5px var(--color-hover), 0 0 15px var(--color-hover);
      }
  }
}
