.contact-list{
    
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;


    &::-webkit-scrollbar{
        width: 0.3rem;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
        width: 0.3rem;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50rem;
    }
    &::-webkit-scrollbar-track{
        background-color: rgba(255, 255, 255, 0.0);
    }



    &__card{
        transition: 0.2s ease-in-out;
        &--selected{
            background-color: rgba(255, 255, 255, 0.15);
        }
    }
}