.glass-pop-up{
    @include glassmorph($opacity: 0.05, $blur: 10px);
    padding: 2rem;
    animation: scale-in 0.3s ease-in-out, fade-in 0.3s ease-in-out;
}


@keyframes scale-in{
    from{
        transform: scale(0);
    }
    to{
        transform: scale(1);
    }
}
@keyframes fade-in{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}