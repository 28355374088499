*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-size: inherit;
    font-family: inherit;
}

body{
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: $font-roboto;
}

html{
    font-size: 62.5%;
}

ol, ul{
    list-style: none;
}

a{
    text-decoration: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: 0.3s ease-in-out, background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
}


.App{
    position: relative;
}