.login-form{



    &__btn{
        padding: 1rem;
        width: 100%;
        max-width: 20rem;
        margin: 2rem;
    }
}