.form{
    width: 100%;
    height: 100%;
    // padding: 1rem;
    // background-color: aqua;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;


    &__textbox{
        width: 80%;
        // margin: auto;
    }
}