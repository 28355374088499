.glass-contact-card{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 9fr;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1rem;
    
    &__image{
        margin: auto;
        width: 50%;
        aspect-ratio: 1;
        border-radius: 50rem;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        // background-image: url(var(--image));
    }
    &__content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}