.glass-textbox{
    --color: hsl(var(--h), var(--s), var(--l));
    backdrop-filter: blur(10px);
    // width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 15fr 2fr;
    margin-top: 2rem;
    border-radius: 0.3rem;
    cursor: text;
    background-color: hsla(var(--h), var(--s), var(--l), var(--opacity));
    // border-bottom: 2px solid white;
    
    
    &__input{
        width: 100%;
        padding: 1rem;
        box-shadow: inset 1px 1px 2px transparent, inset -1px -1px 2px transparent;
        border: 2px solid transparent;
        outline: transparent;
        background-color: transparent;
        // border-bottom: 2px solid white;
        transition: 0.2s ease-in-out;
        color: var(--color);
        letter-spacing: 1px;

        &::placeholder{
            text-transform: capitalize;
            // color: $color-light;
            color: transparent;
            opacity: 0.45;
            user-select: none;
        }
    
        &:focus{
            box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.5), inset -1px -1px 2px rgba(255, 255, 255, 0.5);
            border: 2px solid transparent;
        }
    }

    &__placeholder{
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 1rem;
        text-transform: capitalize;
        color: var(--color);
        opacity: 0.45;
        transition: 0.2s ease-in-out;
        cursor: text;
    }
    
    &__input:focus ~ &__placeholder, &__input:not(:placeholder-shown)  ~ &__placeholder{
        bottom: 100%;
        font-size: 1.2rem;
        opacity: 0.6;
        transform: translateY(-20%);
    }

    &__indicator{
        width: min(1rem, 30%);
        aspect-ratio: 1;
        border-radius: 50rem;
        margin: auto;
        transition: 0.2s ease-in-out;

        &--neutral{
            $color: yellow;
            background-color: $color;
            box-shadow: 0px 0px 2px $color, 0px 0px 5px $color, 0px 0px 5px $color, 0px 0px 7px $color, 0px 0px 10px $color, 0px 0px 12px $color, 0px 0px 15px $color, 0px 0px 17px $color, 0px 0px 20px $color;
        }
        &--correct{
            $color: rgb(0, 255, 0);
            background-color: $color;
            box-shadow: 0px 0px 2px $color, 0px 0px 5px $color, 0px 0px 5px $color, 0px 0px 7px $color, 0px 0px 10px $color, 0px 0px 12px $color, 0px 0px 15px $color, 0px 0px 17px $color, 0px 0px 20px $color;
        }
        &--incorrect{
            $color: rgb(255, 39, 39);
            background-color: $color;
            box-shadow: 0px 0px 2px $color, 0px 0px 5px $color, 0px 0px 5px $color, 0px 0px 7px $color, 0px 0px 10px $color, 0px 0px 12px $color, 0px 0px 15px $color, 0px 0px 17px $color, 0px 0px 20px $color;
        }
    }


    
}