.dashboard {
  width: 100%;
  height: 100%;
  // background-color: aqua;
  display: grid;
  grid-template-columns: 3fr 9fr;

  &__left {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
  }

  &__profile {
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
  }

  &__btn-group{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  &__btn{
    padding: 0rem 0.75rem;
    aspect-ratio: 1;
    border-radius: 50rem;
  }
}
