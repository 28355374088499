$color-primary: hsl(214, 100%, 42%);
// $color-primary: hsl(331, 32%, 30%);
$color-primary-dark: darken($color-primary, 20);
$color-primary-light: lighten($color-primary, 20);

$color-secondary: adjust-hue($color-primary, 180);
$color-secondary-dark: darken($color-secondary, 20);
$color-secondary-light: lighten($color-secondary, 20);

$color-dark: darken($color-primary, 50);
$color-light: lighten($color-primary, 50);





$font-audiowide: 'Audiowide', cursive;
$font-black-ops: 'Black Ops One', cursive;
$font-oswald: 'Oswald', sans-serif;
$font-press: 'Press Start 2P', cursive;
$font-roboto: 'Roboto', sans-serif;
$font-share: 'Share Tech Mono', monospace;
$font-syne: 'Syne', sans-serif;
$font-teko: 'Teko', sans-serif;
$font-tektur: 'Tektur', cursive;



// Breakpoints for different screen sizes used in responsive.scss
// Small Mobiles --- 0px - 360px
// Mobiles --- 361px - 768px
// Tabs --- 769px - 1024px
// Desktop --- 1025px - 1920px
// Large Screens --- Default CSS styles
$breakpoint-mobile-small: 360px;
$breakpoint-mobile: 768px;
$breakpoint-tab: 1024px;
$breakpoint-desktop: 1920px;