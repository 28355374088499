.settings{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 9fr;

    &__left{
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
    }
    &__right{
        background-color: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(10px);
        padding: 2rem 10%;
        
    }
}