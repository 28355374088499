.button3d {
  --color: hsl(var(--h), var(--s), var(--l));
  cursor: pointer;
  padding: 1rem 2rem;
  min-width: 20rem;
  border-radius: 50rem;
  outline: 2px solid transparent;
  outline-offset: -0.1rem;
  border: 2px solid var(--color);
  color: hsl(var(--h), var(--s), calc(var(--l) + 50%));
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  letter-spacing: 1px;
  --shade: 15%;
  $shadow: 0.3rem;

  &--focus {
    background-image: linear-gradient(
      to bottom right,
      var(--color),
      hsl(var(--h), var(--s), calc(var(--l) + var(--shade)))
    );

    &:hover {
      box-shadow: inset $shadow $shadow $shadow * 2
          hsl(var(--h), var(--s), calc(var(--l) + var(--shade))),
        inset (-1) * $shadow (-1) * $shadow $shadow * 2
          hsl(var(--h), var(--s), calc(var(--l) - var(--shade)));
      outline: 2px solid var(--color);
      outline-offset: 0.3rem;
    }
    &:active {
      box-shadow: inset $shadow $shadow $shadow * 2
          hsl(var(--h), var(--s), calc(var(--l) - var(--shade))),
        inset (-1) * $shadow (-1) * $shadow $shadow * 2
          hsl(var(--h), var(--s), calc(var(--l) + var(--shade)));
    }
  }
  &--dull {
    // background-image: linear-gradient(to bottom right, $color-secondary, $color-secondary-light);
    background-color: transparent;
    backdrop-filter: blur(5px);
    $opacity: 0.7;

    &:hover {
      box-shadow: inset $shadow $shadow $shadow * 2 hsla(var(--h), var(--s), calc(var(--l) + 50%), $opacity),
        inset (-1) * $shadow (-1) * $shadow $shadow * 2
        hsla(var(--h), var(--s), calc(var(--l) - 50%), $opacity);
      outline: 2px solid var(--color);
      outline-offset: 0.3rem;
    }
    &:active {
      box-shadow: inset $shadow $shadow $shadow * 2 hsla(var(--h), var(--s), calc(var(--l) + 50%), $opacity),
        inset (-1) * $shadow (-1) * $shadow $shadow * 2
        hsla(var(--h), var(--s), calc(var(--l) - 50%), $opacity);
    }
  }
}
