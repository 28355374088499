.heading-with-line{
    width: 100%;
    height: fit-content;
    // color: greenyellow;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 1rem;
    overflow: hidden;


    &__text{
        position: relative;
        padding: 1rem;
        border-radius: 50rem;


    }
    &__line{
        width: 100%;
        height: 2px;
        background-color: aqua;
        min-width: 10rem;
        max-width: 20rem;
        border-radius: 50rem;
    }
}