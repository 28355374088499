.content-container {
    width: 100%;
    height: 100cqh;
    position: relative;
    // background-color: aqua;
  
    &__header {
      width: 100%;
      height: 100%;
      background-color: azure;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      $alpha: 0.0;
      $color: $color-primary;
      background-image: linear-gradient(
          to bottom right,
          rgba(darken($color, 20), 0.9) 10%,
          rgba($color, $alpha),
          rgba(lighten($color, 20), 0.9) 100%
        ),
        url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80);
        // url(https://source.unsplash.com/random/1000x500?technology);
      background-size: cover;
      background-position: center;
      position: relative;
    }
  
    &__logo {
      position: absolute;
      top: min(5rem, 5vh);
      left: min(5rem, 5vw);
      width: 100%;
      max-width: fit-content ;
      min-width: 5rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
  
      // border: 2px solid;
  
      &__image {
        width: 100%;
        max-width: 7rem;
        filter: brightness(2);
      }
  
      &__brand{
        
      }
      &__webname{
        font-size: 2.5rem;
        font-family: $font-audiowide;
        color: white;
        font-weight: 400;
        letter-spacing: 1px;
      }
      &__company-name{
        font-size: 1rem;
        font-family: $font-syne;
        color: white;
        opacity: 0.7;
        text-transform: capitalize;
      }
  }
  
      &__glass {
        // width: max(30rem, 25%);
        width: 30%;
        max-width: 40rem;
        aspect-ratio: 1;
        min-height: fit-content;
        border-radius: 1rem;
      }
  }
  